import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import PageBlock from "../../components/major-pieces/pageblock/PageBlock"
import ERIPageBlocks from "../../components/major-pieces/pageblock/ERIPageBlocks"
import getPageBlockFromData from "../../components/major-pieces/pageblock/PageBlock"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/live.css"


function ERI () {
	
	

	const location = ""
	

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content livestream">

				<h1>Saint John's Livestream</h1>
		<p>We regularly livestream Saturday Vespers (7:15pm), Sunday morning Matins (9:15am) and Divine Liturgy (10:00am), and weekday Festal Services. Occasionally we livestream Baptisms, Weddings, and some Classes. 
		</p>
		<br />
		<p>
If video settings are on "Auto," the resolution will gradually step up to the highest available (1080 max). This can take a few minutes before the highest resolution is reached.
		</p>
		<br />
		<p>
Outages and interruptions do occur at times. These can be caused by our own internet provider (GCI) or they can be due to the viewer's internet connection. If video is completely lost, wait a few seconds and re-try to start again. </p>
								<div className="live-wrapper">
					<iframe className="vimeo-livestream" src="https://vimeo.com/event/47787/embed" width="1066" height="600" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>				</div>
			</main>
			<Footer />		
		</div>
		)
}

export default ERI 

//<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/415560943" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
//IBM IFRAME
//					<iframe className='ibm-iframe' src="https://video.ibm.com/embed/23847357" webkitallowfullscreen="true" allowFullScreen frameBorder="no"></iframe>
//					<iframe className='ibm-iframe' src="https://video.ibm.com/embed/23847357" webkitallowfullscreen="true" allowFullScreen frameBorder="no"></iframe>
